import React from 'react';
import styled, {keyframes} from 'styled-components';

const growPendingCircle =keyframes`
  0% {
    opacity: 1;
    r: 150;
  }
  100% {
    opacity: 0;
    r: 300;
  }
`;

const shrinkPendingCircle =keyframes`
  0% {
    opacity: 0;
    r: 130;
  }
  100% {
    opacity: 1;
    r: 30
  }
`;

const SVG = styled.svg`
  max-width: 20vmin;
  max-height: 12vmin;
  margin: 0 auto;
  position:relative;
  overflow: visible;
    
  
    
    &>g#earthPulse circle {
      animation: ${growPendingCircle} 3s infinite linear;
      stroke:#07c;
      stroke-width: 3px;
      stroke-opacity: 0.3;
      fill-opacity: 0.25;
      &:nth-of-type(2) {
        fill-opacity: 0.5;
        stroke-opacity: 0.6;
        animation-delay: 1s;
      }
    }
    &>g#moonPulse circle {
      animation: ${shrinkPendingCircle} 3s infinite linear;
      stroke:#07c;
      stroke-width: 3px;
      stroke-opacity: 0.25;
      fill-opacity: 0.15;
      &:nth-of-type(1) {
        animation-delay: 0.4s;
      }
      &:nth-of-type(2) {
        stroke-opacity: 0.35;
        fill-opacity: 0.05;
        animation-delay: 1s;
      }
    }
`;

export default function LogoPulse (props) {
    return (

        <SVG viewBox="0 0 331 331"  strokeLinejoin={'round'} className={props.className}>
          <g id={"earthPulse"}>
            <circle cx={'155'} cy={'175'} r={'300'} fill={'url(#glow)'}/>
            <circle cx={'155'} cy={'175'} r={'260'} fill={'url(#glow)'}/>
          </g>
          <g id={"moonPulse"}>
            <circle cx={'300'} cy={'31'} r={'100'} fill={'url(#glow)'}/>
            <circle cx={'300'} cy={'31'} r={'70'} fill={'url(#glow)'}/>
          </g>
        {/*</svg>*/}
        {/*/!*<img alt="90E logo animation" src={LogoMark} style={{margin: '0 auto', display: 'block'}} />*!/*/}
        {/*<svg width="100%" height="100%" viewBox="0 0 331 331" strokeLinejoin={'round'}>*/}
          <g>
            <path id="south_ocean" d="M155.465,175.65l-144.434,0c-0.005,28.932 3.318,47.234 17.964,69.829l126.445,1.032l0.025,-70.861Z" fill={'#39d9b7'}/>
            <path id="north_ocean" d="M155.465,103.118l-127.063,0.366c-11.075,20.43 -17.365,47.295 -17.37,72.166l144.433,0l0,-72.532Z" fill={'#97c8e8'}/>

            <g id="meridians" fill={'none'} stroke={'#1c2a4e'} strokeWidth={'20px'}>
              <path d="M29.318,246.202l126.123,0" />
              <path d="M11.072,175.648l144.369,-0.012" />
              <path d="M31.391,101.66l124.05,0" />
              <path d="M155.441,31.209l0,288.882" />
            </g>

            <path id="earth" d="M220.799,99.955l0,141.396c0,1.286 0.511,2.52 1.421,3.43c0.909,0.909 2.143,1.421 3.43,1.421l57.051,0c-25.157,42.494 -74.341,73.889 -127.26,73.889c-79.719,0 -144.441,-64.722 -144.441,-144.441c0,-79.719 64.722,-144.441 144.441,-144.441c79.719,0 144.441,64.722 144.441,144.441c0,6.465 -1.294,16.351 -2.499,24.128" fill={'none'} stroke={'#1c2a4e'} strokeWidth={'20px'} strokeLinecap={'round'} strokeMiterlimit={'2'} />
            <circle id="moon" cx="299.452" cy="31.209" r="23.209" fill={'none'} stroke={'#1c2a4e'} strokeWidth={'15px'} strokeLinecap={'round'}/>
          </g>
          <defs>
            <radialGradient id="glow">
              <stop offset="80%" stopOpacity={0} stopColor="#97c8e8" />
              <stop offset="95%" stopOpacity={0.5} stopColor="#97c8e8" />
            </radialGradient>
          </defs>
        </SVG>
    )
}

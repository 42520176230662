import styled from 'styled-components';

export const Text = styled.span`
  display: inline-block;
  width: fit-content;
  margin-left: 16px;
  font-weight: ${({ size }) => () => {
    switch (size) {
      case 'large':
        return '400';
      default:
        return '500';
    }
  }};
  font-size: ${({ size }) => () => {
    switch (size) {
      case 'large':
        return '3.2rem';
      default:
        return '2rem';
    }
  }};
  line-height: 0.9;

  & > span {
    font-family: jostheavy;
    text-transform: uppercase;
    font-size: 28px;
    color: var(--90e-teal);
    max-width: fit-content;
    @media(max-width: 1070px) {
      display: none;
    }
`;

export const Ticker = styled.span`
  margin-right: 24px;
  color: var(--90e-cyan) !important;
  position: relative;
  &:after {
    content: '';
    width: 1px;
    height: 100%;
    background-color: var(--90e-teal);
    position: absolute;
    right: -12px;
    top: 0;
  }
`;

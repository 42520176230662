import React from 'react';
import SplitText from 'react-pose-text';
import { Text, Ticker } from './title.css';

  let charPoses;
  charPoses = {
    exit: { x: 5, opacity: 0 },
    enter: {
      x: 0,
      opacity: 1,
      transition: ({ charInWordIndex }) => ({
        type: 'spring',
        delay: charInWordIndex * 40 + 70,
        stiffness: 500,
        damping: 500,
      }),
    },
  };
export default function Title(props){
    return (
      <Text>
      <span style={{visibility: 'hidden', maxWidth: '0px', maxHeight: '0px', display: 'block'}}>
        90East
      </span>
        <Ticker>
          <SplitText initialPose="exit" pose="enter" charPoses={charPoses}>
            {props.prefix ? props.prefix : 'Experience' }
          </SplitText>
        </Ticker>
        <span>Integrated</span>
      </Text>
    );
};
import React from 'react';
import styled, {keyframes} from 'styled-components';
// import Logo_90E from './svg/90e_logo.svg';


const draw = keyframes`
  from {
    stroke-dashoffset: 1234px;
  }
  to {
    stroke-dashoffset: 0px;
  }
`;

const scaleOcean = keyframes`
  from {
    transform: scaleX(0)
  }
  to {
    transform: scaleX(1)
  }
`;

const LogoContainer = styled.svg `
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linejoin: round;
  stroke-miterlimit: 1.41421;
  max-height: 8vh;
  
  path,
  circle {
    stroke-dasharray: 1234px;
    animation-name: ${draw};
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  }
    
  #north_ocean,
  #south_ocean {
    transform: scaleX(0);
    transform-origin: 50%;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  }
  
  #north_ocean,
  #south_ocean {
    animation-name: ${scaleOcean};
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-delay: 3s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    ; 
  }
  
`;

export default class DesignAnim extends React.Component {
  render() {
    return (
      <LogoContainer style={this.props.style} className={this.props.className} width="100%" height="100%" viewBox="0 0 384 385" >
            <g>
              <path id="south_ocean"
                    d="M180.467,204.5l-168.026,0c-0.006,33.658 3.86,54.949 20.898,81.235l147.099,1.201l0.029,-82.436Z"
                    style={{fill: '#39d9b7'}} />
              <path id="north_ocean"
                    d="M180.467,120.121l-147.817,0.426c-12.884,23.767 -20.202,55.02 -20.207,83.953l168.024,0l0,-84.379Z"
                    style={{fill: '#97c8e8'}} />
              <g id="meridians">
                <path d="M33.715,286.575l146.724,0"
                      style={{fill: 'none', stroke: '#1c2a4e', strokeWidth: '24px', strokeLinecap: 'round'}} />
                <path d="M12.489,204.498l167.95,-0.014"
                      style={{fill: 'none', stroke: '#1c2a4e', strokeWidth: '24px', strokeLinecap: 'round'}} />
                <path d="M36.126,118.425l144.313,0"
                      style={{fill: 'none', stroke: '#1c2a4e', strokeWidth: '24px', strokeLinecap: 'round'}} />
                <path d="M180.439,36.467l0,336.066"
                      style={{fill: 'none', stroke: '#1c2a4e', strokeWidth: '24px', strokeLinecap: 'round'}} />
              </g>
              <path id="earth"
                    d="M256.472,116.441c0,0 0,111.447 0,153.941c0,4.295 1.706,8.414 4.743,11.45c3.037,3.037 7.156,4.743 11.45,4.743c21.235,0 55.82,0 55.82,0c-29.265,49.436 -86.484,85.958 -148.046,85.958c-92.74,0 -168.034,-75.293 -168.034,-168.033c0,-92.74 75.294,-168.033 168.034,-168.033c92.74,0 168.033,75.293 168.033,168.033c0,7.521 -1.505,19.022 -2.907,28.07"
                    style={{
                      fill: 'none',
                      stroke: '#1c2a4e',
                      strokeWidth: '24px',
                      strokeLinecap: 'round',
                      strokeMiterlimit: 2
                    }}/>
              <circle id="moon" cx="347.972" cy="36.467" r="27"
                      style={{fill: 'none', stroke: '#1c2a4e', strokeWidth: '18px', strokeLinecap: 'round'}} />
            </g>
      </LogoContainer>
    )
  }
}
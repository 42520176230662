import React from 'react';
import styled from 'styled-components';


const LogoContainer = styled.div`
  max-width: 128px;
  min-width: 128px;
`;

export default class Logo_90E extends React.Component {
  render() {
    return (
      <LogoContainer style={this.props.style}>
        <svg width="100%" height="100%" viewBox="70 10 1050 500" xmlns="http://www.w3.org/2000/svg" style={{fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: '1.5' }}>

          <g>
            <path id="south_ocean" d="M270.756,263.5l-168.026,0c-0.005,33.658 3.86,54.949 20.898,81.235l147.099,1.201l0.029,-82.436Z" style={{fill: '#39d9b7'}}/>
            <path id="north_ocean" d="M270.756,179.121l-147.817,0.426c-12.884,23.767 -20.202,55.02 -20.207,83.953l168.024,0l0,-84.379Z" style={{fill: '#97c8e8'}}/>

            <g id="meridians">
              <path d="M124.004,345.575l146.724,0" style={{ fill:'none', stroke: '#1c2a4e', strokeWidth: '24px'}}/>
              <path d="M102.778,263.498l167.95,-0.014" style={{ fill:'none', stroke: '#1c2a4e', strokeWidth: '24px'}}/>
              <path d="M126.415,177.425l144.313,0" style={{ fill:'none', stroke: '#1c2a4e', strokeWidth: '24px'}}/>
              <path d="M270.728,95.467l0,336.066" style={{ fill:'none', stroke: '#1c2a4e', strokeWidth: '24px'}}/>
            </g>

            <path id="earth" d="M346.761,175.441l0,153.941c0,4.295 1.706,8.414 4.743,11.45c3.037,3.037 7.156,4.743 11.45,4.743c21.235,0 55.82,0 55.82,0c-29.265,49.436 -86.484,85.958 -148.046,85.958c-92.74,0 -168.034,-75.293 -168.034,-168.033c0,-92.74 75.294,-168.033 168.034,-168.033c92.74,0 168.033,75.293 168.033,168.033c0,7.521 -1.505,19.022 -2.907,28.07" style={{fill:'none', stroke:'#1c2a4e', strokeWidth:'24px', strokeLinecap: 'round', strokeMiterlimit: '2'}}/>
            <circle id="moon" cx="438.261" cy="95.467" r="27" style={{fill:'none', stroke:'#1c2a4e', strokeWidth: '18px', strokeLinecap: 'round'}}/>
          </g>

          <g>
            <path d="M571.622,343.594c2.704,0 5.224,-1.366 6.699,-3.632c7.451,-11.447 29.952,-46.013 29.952,-46.013c8.43,-12.88 14.519,-23.418 18.032,-31.146c3.746,-7.962 5.62,-16.393 5.62,-25.057c0,-16.158 -6.089,-30.209 -18.5,-41.684c-12.177,-11.474 -26.931,-17.329 -44.494,-17.329c-17.797,0 -33.019,5.855 -45.43,17.563c-12.412,11.475 -18.735,25.526 -18.735,41.918c0,18.032 8.197,34.19 19.203,42.855c11.24,8.664 23.652,13.816 33.956,13.816c0.702,0 1.873,0 3.044,-0.234c0,0 -17.515,25.962 -27.032,40.068c-1.177,1.745 -1.295,3.997 -0.307,5.855c0.988,1.859 2.921,3.02 5.026,3.02l32.966,0Zm-16.976,-91.329c-7.728,-8.196 -7.728,-20.139 0,-27.867c8.196,-7.728 20.608,-7.728 28.336,0c7.728,7.493 7.728,20.139 0,27.867c-7.728,7.728 -20.14,7.728 -28.336,0Z" style={{fill: '#1c2a4e', fillRule: 'nonzero'}}/>
            <path d="M643.461,295.822c1.64,14.285 8.431,26.228 19.906,35.829c11.474,9.601 25.291,14.285 40.981,14.285c16.392,0 30.677,-4.684 42.62,-14.285c11.943,-9.601 18.968,-21.544 20.608,-35.829c0.468,-4.918 0.702,-16.392 0.702,-33.956c0,-17.563 -0.234,-28.804 -0.702,-33.721c-1.64,-14.285 -8.665,-26.228 -20.608,-35.83c-11.943,-9.601 -26.228,-14.284 -42.62,-14.284c-15.69,0 -29.507,4.683 -40.981,14.284c-11.475,9.602 -18.266,21.545 -19.906,35.83c-0.468,4.917 -0.702,16.158 -0.702,33.721c0,17.564 0.234,29.038 0.702,33.956Zm41.918,-4.918c-0.468,-3.512 -0.702,-13.348 -0.702,-29.038c0,-14.753 0.234,-24.12 0.702,-27.633c0.937,-9.601 9.133,-16.861 20.14,-16.861c10.069,0 18.968,7.26 20.139,16.861c0.468,3.513 0.702,12.88 0.702,27.633c0,15.69 -0.234,25.526 -0.702,29.038c-1.171,9.601 -10.07,16.861 -20.139,16.861c-11.007,0 -19.203,-7.26 -20.14,-16.861Z" style={{fill: '#1c2a4e', fillRule: 'nonzero'}}/>
            <path
              d="M844.004,343.594c2.09,0 4.094,-0.83 5.572,-2.308c1.478,-1.478 2.308,-3.482 2.308,-5.572c0,-0.366 0,-0.734 0,-1.1c0,-2.09 -0.83,-4.095 -2.308,-5.573c-1.478,-1.477 -3.482,-2.308 -5.572,-2.308c-12.53,0 -35.594,0 -35.594,0l0,-60.886l29.505,0c2.09,0 4.094,-0.83 5.572,-2.308c1.478,-1.478 2.308,-3.482 2.308,-5.572c0,-0.366 0,-0.734 0,-1.1c0,-2.09 -0.83,-4.095 -2.308,-5.573c-1.478,-1.477 -3.482,-2.308 -5.572,-2.308c-10.968,0 -29.505,0 -29.505,0l0,-51.753l33.954,0c2.09,0 4.095,-0.83 5.572,-2.308c1.478,-1.478 2.309,-3.482 2.308,-5.572c0,-0.366 0,-0.734 0,-1.1c0.001,-2.09 -0.83,-4.095 -2.308,-5.573c-1.477,-1.477 -3.482,-2.308 -5.572,-2.308l-42.353,0c-2.617,0 -5.127,1.04 -6.977,2.891c-1.851,1.85 -2.89,4.36 -2.89,6.977c0,29.105 0,114.381 0,143.487c0,2.617 1.039,5.127 2.89,6.977c1.85,1.851 4.36,2.89 6.977,2.89c12.216,0 32.776,0 43.993,0Z"
              style={{fill: '#1c2a4e', fillRule: 'nonzero'}}/>
            <path d="M941.474,335.565l-36.144,-147.661l-36.128,147.683m9.537,-38.987l53.111,0" style={{fill:'none', stroke:'#1c2a4e', strokeWidth:'16px', strokeLinecap:'round', strokeMiterlimit:'2'}}/>
            <path d="M1024.48,227.023l0,-8.212c0,-7.8 -3.098,-15.281 -8.614,-20.796c-5.515,-5.516 -12.996,-8.614 -20.796,-8.614c-0.379,0 -0.758,0 -1.138,0c-7.8,0 -15.28,3.098 -20.796,8.614c-5.515,5.515 -8.614,12.996 -8.614,20.796c0,3.811 0,7.629 0,11.204c0,11.505 6.697,21.957 17.15,26.765c7.921,3.644 17.305,7.96 25.227,11.604c10.452,4.807 17.149,15.26 17.149,26.764c0,3.219 0,6.63 0,10.046c0,7.8 -3.098,15.28 -8.614,20.796c-5.515,5.515 -12.996,8.614 -20.796,8.614c-0.608,0 -1.216,0 -1.824,0c-7.8,0 -15.281,-3.099 -20.796,-8.614c-5.516,-5.516 -8.614,-12.996 -8.614,-20.796c0,-4.998 0,-8.611 0,-8.611" style={{fill:'none', stroke:'#1c2a4e', strokeWidth:'16px', strokeLinecap:'round', strokeMiterlimit:'2'}}/>
            <path d="M1111.47,187.734l-72.272,0m36.224,1.546l0.084,146.325" style={{fill:'none', stroke:'#1c2a4e', strokeWidth:'16px', strokeLinecap:'round', strokeMiterlimit:'2'}}/>
          </g>
        </svg>
      </LogoContainer>
    )
  }
}
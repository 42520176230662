import React from 'react';

const SVGStyle = {
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  strokeLinejoin: 'round',
  strokeMiterlimit: 1.41421,
  position: 'absolute',
  userSelect: 'none',
};

const Ribbons = [
  <svg key={'ribbon_0'} viewBox="0 -120 1920 2196" style={{ ...SVGStyle }}>
    <g transform="matrix(1,0,0,1,-0.592902,-1499.02)">
      <g transform="matrix(1,-0.411969,9.75182e-17,-0.796296,160.921,2822.92)">
        <path
          d="M1759.6,687.259L1759.24,-1011.19C1524.95,-793.303 1072.6,-409.405 660.367,-271.241C-38.178,-37.116 -160.328,153.352 -160.328,153.352L-160.328,752.233C-160.328,752.233 -154.539,601.657 530.315,639.739C713.717,649.938 1183.22,826.566 1759.6,687.259Z"
          style={{
            fill: 'url(#_Linear1)',
            strokeWidth: '2px',
            stroke: 'var(--90e-teal)',
            strokeOpacity: 0.4
          }}
        />
      </g>
    </g>

    <defs>
      <linearGradient
        id="_Linear1"
        x1="0"
        y1="0"
        x2="1"
        y2="0"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(2.20436e-13,-1506.98,-1200,620.828,1539.67,734.959)"
      >
        <stop
          offset="0"
          style={{ stopColor: 'rgb(150,229,255)', stopOpacity: 0.75 }}
        />
        <stop
          offset="1"
          style={{ stopColor: 'rgb(57,217,183)', stopOpacity: 0.75 }}
        />
      </linearGradient>

      <filter id="shadow">
        <feDropShadow dx="12" dy="0" stdDeviation="6" />
      </filter>
    </defs>
  </svg>,
  <svg key={'ribbon_0'} viewBox="0 0 1920 2496" style={SVGStyle}>
    <g transform="matrix(1,0,0,1,-3,500)">
      <path
        d="M1924.05,662.18l-3.375,1021.85c-669.724,291.149 -1229.07,-548.886 -1920.67,-381.564l2.767,-1302.47c768.709,49.472 1393.32,758.051 1921.28,662.18Z"
        style={{
          fill: 'transparent',
          strokeWidth: '2px',
          stroke: 'rgba(255,255,255,0.3)',
          // filter: 'url(#shadow)',
        }}
      />
      <path
        d="M1924.05,662.18l-3.375,1021.85c-669.724,291.149 -1229.07,-548.886 -1920.67,-381.564l2.767,-1302.47c768.709,49.472 1393.32,758.051 1921.28,662.18Z"
        style={{ fill: 'url(#_Linear2)' }}
      />
    </g>

    <defs>
      <linearGradient
        id="_Linear2"
        x1="0"
        y1="0"
        x2="1"
        y2="0"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(4.02014e-14,656.539,-656.539,4.02014e-14,824.334,159.207)"
      >
        <stop
          offset="0"
          style={{ stopColor: 'rgb(40,90,220)', stopOpacity: 0.5 }}
        />
        <stop offset="1" style={{ stopColor: '#fff', stopOpacity: 0.2 }} />
      </linearGradient>
      <filter id="shadow">
        <feDropShadow dx="12" dy="0" stdDeviation="6" />
      </filter>
    </defs>
  </svg>,
];

export default function Ribbon (props) {
    return <div style={props.style}>{Ribbons[props.ribbon]}</div>;
}

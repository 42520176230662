import React from 'react';
import styled, {keyframes} from 'styled-components';

const zoomInGlobe = keyframes`
  from{
    transform: matrix(1.68121,0,0,1.01687,47.0739,27.4554) scale(0.6);
  }
  to {
    transform: matrix(1.68121,0,0,1.01687,47.0739,27.4554) scale(0.75);
  }
`;

const zoomInLoupe = keyframes`
  from {
    transform: translate(-3%, 8.6%) scale(0.9);
  }
  to {
    transform: translate(-3%, 8.6%) scale(1.2);
  }
`;


const Blur = keyframes`
  from{
    filter: blur(2px);
  }
  to {
    filter: blur(0px);
  }
`;


const SVG = styled.svg`
  max-height: 10vh;
  overflow: visible;
  animation: ${Blur} 5s linear alternate infinite;
  
  #Globe {
    animation: ${zoomInGlobe} 5s linear alternate infinite;
    transform-origin: 10% 40%;
  }
  #Loupe {
    animation: ${zoomInLoupe} 5s linear alternate-reverse infinite;
    transform-origin: 20% 30%;
  }
`;

export default function ConsultancyAnim (props) {
    return (
      <SVG width="100%" height="100%" viewBox="-211 0 765 422" version="1.1" style={{fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeLinecap:'round', strokeMiterlimit: '1.5'}}>
        <g id={"Globe"} transform="matrix(1.68121,0,0,1.01687,47.0739,27.4554) scale(0.6)">
          <g transform="matrix(0.594809,0,0,0.983412,-18.4831,16.2701)">
            <g>
              <path id="south_ocean" d="M155.465,175.65L11.031,175.65C11.026,204.582 14.349,222.884 28.995,245.479L155.44,246.511L155.465,175.65Z" style={{fill: 'rgb(57,217,183)'}}/>
              <path id="north_ocean" d="M155.465,103.118L28.402,103.484C17.327,123.914 11.037,150.779 11.032,175.65L155.465,175.65L155.465,103.118Z" style={{fill: 'rgb(151,200,232)'}}/>

              <g id="meridians" style={{ fill: 'none', stroke: 'rgb(28,42,78)', strokeWidth: '20px'}}>
                <path d="M29.318,246.202L155.441,246.202" />
                <path d="M11.072,175.648L155.441,175.636" />
                <path d="M31.391,101.66L155.441,101.66" />
                <path d="M155.441,31.209L155.441,320.091" />
              </g>
              <path id="earth" d="M220.799,99.955L220.799,241.351C220.799,242.637 221.31,243.871 222.22,244.781C223.129,245.69 224.363,246.202 225.65,246.202L282.701,246.202C257.544,288.696 208.36,320.091 155.441,320.091C75.722,320.091 11,255.369 11,175.65C11,95.931 75.722,31.209 155.441,31.209C235.16,31.209 299.882,95.931 299.882,175.65C299.882,182.115 298.588,192.001 297.383,199.778" style={{fill: 'none', stroke: 'rgb(28,42,78)', strokeWidth: '20px', strokeLinecap: 'round', strokeMiterlimit: '2'}}/>
            </g>
          </g>
        </g>
        <g id={"Loupe"}>
          <circle cx={'19%'} cy={'43%'} r={'24%'} fill={'transparent'} strokeWidth={20} stroke={'var(--90e-navy)'} />
          <line x1={'260'} y1={'280'} x2={'360'} y2={'360'} strokeWidth={20} stroke={'var(--90e-navy)'} />
        </g>
      </SVG>
    )
}
import styled, {css} from 'styled-components';
import Terminal from './svg/terminal.svg';
import IPhone from './img/ifeun.png';
import IPad from './img/ipet.png';
import Hero from './img/jj-ying-7JX0-bfiuxQ-unsplash.jpg';

export const Content = styled.div`
  flex: 1;
  width: 100vw;
`;

export const Segment = styled.section`
  position: relative;
  padding: 164px 5vmin 2.5vmax;
  display: flex;
  flex-direction: column;
  min-height: 75vh;
  width: 100vw;
  &:nth-of-type(1){
    padding: 2.5vmax 0;
    min-height: 100vh;
    text-shadow: 0 0 4px #fff;
  }
`;

export const SegmentHeading = styled.h3`
  text-transform: uppercase;
  padding-top: 2vmin;
  margin-bottom: 32px;
  position:relative;
  text-align: center;
  width: 100%;
  font-family: jostbold, jostbook, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
`;

export const SegmentIntro = styled.h4`
  text-align: center;
  max-width: 90vw;
  margin: 0 auto;
  font-family: jostbook, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: normal;
  line-height: 1.4;
  margin-bottom: 32px;
  color: var(--90e-grey);
`;

export const SegmentText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex: 1;
  //margin: 0 auto;
  & > * {
    flex: 1;
    display: block;
    max-width: 45vw;
  }
`;

export const ItemHeader = styled.h4`
  font-family: jostbook, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: normal;
  color: var(--90e-grey);
  margin-bottom: 24px;
`;

export const List = styled.ul`
  color: var(--90e-grey);
  font-size: 18px;
  line-height: 1.8;
  position: relative;
  left: -24px;
  margin-top: 24px;
  list-style-type: none;
  & li{
    padding-left: 12px;
    &::before{
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      background-image: ${()=>{return 'url(' + Terminal + ')'}};
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      position: relative;
      top: 4px;
      left: -12px;
    }
  }
`;

export const Payoff = styled.h1`
  color: var(--90e-navy);
  font-family: jostheavy, jostbold, jostbook, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";;
  font-size: 48px;
  letter-spacing: -1.2px;
  line-height: 1.2;
  text-align: center;
  padding-top: 12.5vh;
  //@media(max-width: 768px) {
  //    padding-top: 5vh;
  //  }
  padding-left: 10vw;
  padding-right: 10vw;
  z-index: 1;
  position: relative;
  font-weight: normal;
  transition: all 0.1s linear;
  transform: rotateX(${props => -props.rot + 'deg'});
  span {
    font-size: 36px;
    font-family: jostbook, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: var(--90e-cyan);
    em{
      color: var(--90e-teal);
      font-style: normal;
    }
  }
`;

export const Blurb = styled.h2`
  color: var(--90e-navy);
  font-family: jostbook, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: normal;
  font-size: 24px;
  letter-spacing: -1.2px;
  line-height: 1.4;
  text-align: center;
  margin: 0 auto;
  margin-top: 48px;
  margin-bottom: 36px;
  z-index: 1;
  max-width: 90vw;
`;

export const Header = styled.header`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 24px;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: hsla(0, 100%, 100%, 0.95);
  width: 100vw;
  min-height: 100px;
  box-sizing: border-box;
  box-shadow: 5px 0 10px 0 rgba(0, 0, 0, 0.14);
 
  div {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  nav{
    height: inherit;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    
    a {
      color: #757575;
      transition: color 0.2s ease;
      text-decoration: none;
      text-transform: uppercase;
      font-variant: small-caps;
      font-size: 16px;
      margin-left: 4px;
      position: relative;
    
      &:after{
        content: '';
        width: 0;
        height: 2px;
        background: var(--90e-cyan);
        position:absolute;
        bottom: -4px;
        left: 12px; //12px to offset the ' / ' divider
        transition: width 0.2s ease-out;
      }
      &:first-of-type:after{
        left: 0;
      }
      
      &:not(:first-of-type):before{
        content: '/  ';
        color: #757575;
      }
      
      &:hover {
        color: inherit;
      }
    
      &:nth-of-type(${props => props.activeIndex}):after{
        width: ${props=> props.activeIndex === 1 ? '100%' : 'calc(100% - 12px)'} ; // -12px to offset the ' / ' divider
      }
    }
  }
  
  ${(props) => {
  if (props.dropdown) {
    return css`
        flex-direction: column;
        position: fixed;

        &:after{
          content: '|||';
          position: absolute;
          top: 36px;
          right: 24px;
          transform: ${props=>props.opened ? 'rotate(0deg)' : 'rotate(90deg)'};
          transition: transform 0.2s ease-out;
        }

        nav {
          max-height: ${props=>props.opened ? '999px' : '0px'};
          overflow: hidden;
          padding-top: ${props=>props.opened ? '24px' : '0px'};
          flex-direction: column;
          align-items: center;
          transition: max-height 0.3s ease-out;
          a{
            outline: none;
            font-size: 36px;
            margin: 18px;
            &:after{
              left: 0;
            }
            &:before{
              display: none;
            }
            &:nth-of-type(${props => props.activeIndex}):after{
              width: 100%; 
            }
          }
        }
      `}
    }
  }
 }`;

export const CTA = styled.button`
  -webkit-appearance: none;-moz-appearance: none;appearance: none;
  margin: 0 auto;
  display: block;
  padding: 16px 24px;
  background: linear-gradient(hsla(0, 0%, 100%, 0.7), hsla(0, 0%, 100%, 0));
  border:none;
  border-radius: 80px;
  font-size: 16px;
  color: var(--90e-cyan);
  font-family: jostbold, jostbook, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  text-transform: uppercase;
  transition: all 0.3s linear;
  box-shadow: 0 0 0 2px var(--90e-cyan);
  position: relative;
  top: 0;
  //background-color: hsla(0,0%,100%,0.5);
  outline: none;
  cursor: pointer;
  &:hover{
    top: -5px;
    box-shadow: 0 0 0 4px var(--90e-teal), 0 12px 24px 0 hsla(0,0%,0%,0.2);
    color: var(--90e-teal);
  }
  &.opaque{
    background: linear-gradient(hsla(0, 0%, 100%, 1), hsla(0, 0%, 100%, 0.7));
  }
`;

export const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 22px;
  width: 100%;
  max-height: 60px;
  color: hsla(0,0%,100%,0.3);
  background-color: var(--90e-navy);
  position:fixed;
  bottom: 0;
  font-size: 16px;
  z-index: 10;
  transition: all 0.5s ease-out;
  transition-property: max-height, color;
  box-shadow: 0 6px 12px inset hsla(0, 0%, 0%, 0.3);
  transition-delay: 0.5s;
  &:hover{
    transition-delay: 0s;
    color: hsla(0,0%,100%,1);
    max-height: 30vh;
  }
  &.opened{
    max-height: 30vh;
    color: hsla(0,0%,100%,1);
  }
  div{
    align-self: flex-end;
    svg, img {
      height: 16px;
      overflow: visible;
      max-width: fit-content;
      margin-left: 12px; 
    }
  }
  h6{
    font-family: jostbold, jostbook, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: inherit;
    font-weight: normal;
    margin-bottom: 20px;
  }
  address{
    font-weight: normal;
    font-style: normal;
    line-height: 24px;
    a{
      color: currentColor;
      text-decoration: none;
    }
  }
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  position: relative;
  ${(props) => {
  if (props.mobile){
    return css`
        ${Payoff}{
          font-size: 36px;
          span {
            font-size: 28px;
          }
        }
        ${Blurb} {
          font-size: 18px;
        }
        ${SegmentText}{
          flex-direction: column;
          & > * {
            max-width: 100vw;
          }
        }
      `;
  }
}}
  & *.ornament {
    position: relative;
    top: 100px;
    opacity: 0;
    transition: all 1s ease-out;
    &.inView{
      top: 0;
      opacity: 1;
    }
  }
`;

export const P = styled.p`
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 24px;
  padding-right: 24px;
`;

export const PhoneScroller = styled.div`
  width: calc(260px / 1.5);
  height: calc(530px / 1.5);
  position: absolute;
  right: -48px;
  bottom: 0;
  background: url(${Hero});
  background-attachment: fixed;
  background-size: cover;
  background-position: left;
  overflow: visible;
  margin: 0 auto;
  &::after{
    content: '';
    display: block;
    position: absolute;
    left: -12px;
    top: -28px;
    width: calc(300px / 1.5);
    height: calc(590px / 1.5);
    max-height: 70vh;
    background: url(${IPhone});
    background-size: contain;
  }
  @media(max-width: 1000px){
    display: none;
  }
`;

export const PadScroller = styled.div`
  width: calc(2732px / 3.9);
  height: calc(2048px / 3.8);
  position: absolute;
  right: -12px;
  background: url(${Hero});
  //background-attachment: fixed;
  background-size: cover;
  overflow: visible;
  margin: 0 auto;
  border-radius: 20px;
    transform: scale(0.85); /*Is this a stupid hack? Why, yes. Yes, it is. But it prevents the tablet from being cut off*/
  &::after{
    content: '';
    display: block;
    position: absolute;
    left: -20px;
    //top: -45px;
    width: calc(2732px / 3.75);
    height: calc(2048px / 3.65);
    max-height: 70vh;
    background: url(${IPad});
    background-size: contain;
  }
`;

export const Devices = styled.div`
   width: 50%; 
   max-width: 45vw; 
   max-height: 70vh; 
   position: relative; 
   top: 48px; 
   //z-index: -1; 
   object-fit: contain;
   overflow: hidden;
   object-position: right;
   &::after{
    content: '';
    display: block;
    width: 1px;
    height: 100%;
    position:absolute;
    //left: -32px;
    top: 0;
    background: linear-gradient(0deg, hsla(0,0%,100%,0) 0%, hsla(0,0%,100%,0.5) 15%, hsla(0,0%,100%,0.5) 85%, hsla(0,0%,100%,0));
    box-shadow: 3px 0 6px #aaa;
   }
   &>*{
    position:absolute;
    right: 30px;
    bottom: 20px;
   }
`;

export const AboutGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: minmax(25vh, auto) auto;
  figure {
    margin: 12px;
    figcaption{
      color: var(--90e-navy);
      font-size: 18px;
      strong {
        color: var(--90e-cyan);
        font-size: 24px;
      }
    }
    img{
      width: 50%;
      border-radius: 50%;
      border: 4px solid var(--90e-cyan);
    }
    &:nth-of-type(2){
      strong {
        color: var(--90e-teal);
      }
      img{
        border-color: var(--90e-teal);
      }
    }
  }
  @media(max-width: 1000px){
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    padding-bottom: 10vh;
  }
  & > div:last-of-type {
    grid-column: 1 / -1;
    grid-row: 3 / 4;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 100vw;
    @media(max-width: 1000px){
      flex-direction: column;
      padding-bottom: 48px
    }
    & img {
      object-fit: contain;
      height: 80px;
      max-height: 80px;
      max-width: 50vw;
      max-width: 20vw;
      margin: 24px;
      opacity: 0.4;
      filter: grayscale(100%);
      transition: all 0.2s ease-in-out;
      &:hover{
        opacity: 1;
        filter: grayscale(0%);
      }
    }
  }
`;
import React from 'react';
import styled, {keyframes} from 'styled-components';

const Wind = keyframes`
  to {
    stroke-dashoffset: 1800;
  }
`;

const SVG = styled.svg`
  width: calc(100% - 5vw);
  max-height: 10vh;
  position: relative;
  left: 5vw;
  .gust {
    stroke-dasharray: 150;
     animation: ${Wind} 5s linear reverse infinite;
  }
  
`;

export default function StreamlineAnim() {
  return (
      <SVG width="100%" height="100%" viewBox="0 0 765 422" version="1.1" style={{fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: '1.5'}}>
        <g id="Artboard1" transform="matrix(1.68121,0,0,1.01687,47.0739,27.4554)">
          <g transform="matrix(0.594809,0,0,0.983412,-18.4831,16.2701)">
            <g>
              <path id="south_ocean" d="M155.465,175.65L11.031,175.65C11.026,204.582 14.349,222.884 28.995,245.479L155.44,246.511L155.465,175.65Z" style={{fill: 'rgb(57,217,183)'}}/>
              <path id="north_ocean" d="M155.465,103.118L28.402,103.484C17.327,123.914 11.037,150.779 11.032,175.65L155.465,175.65L155.465,103.118Z" style={{fill: 'rgb(151,200,232)'}}/>

              <g id="meridians" style={{ fill: 'none', stroke: 'rgb(28,42,78)', strokeWidth: '20px'}}>
                <path d="M29.318,246.202L155.441,246.202" />
                <path d="M11.072,175.648L155.441,175.636" />
                <path d="M31.391,101.66L155.441,101.66" />
                <path d="M155.441,31.209L155.441,320.091" />
              </g>
              <path id="earth" d="M220.799,99.955L220.799,241.351C220.799,242.637 221.31,243.871 222.22,244.781C223.129,245.69 224.363,246.202 225.65,246.202L282.701,246.202C257.544,288.696 208.36,320.091 155.441,320.091C75.722,320.091 11,255.369 11,175.65C11,95.931 75.722,31.209 155.441,31.209C235.16,31.209 299.882,95.931 299.882,175.65C299.882,182.115 298.588,192.001 297.383,199.778" style={{fill: 'none', stroke: 'rgb(28,42,78)', strokeWidth: '20px', strokeLinecap: 'round', strokeMiterlimit: '2'}}/>
            </g>
          </g>
          <g id="gust0" transform="matrix(0.594809,0,0,0.983412,-94.6186,-106.656)">
            <path className={'gust'} d="M126,184C126,184 172.313,99.3 283.441,99.3C340.077,99.3 389.554,124.492 427.882,160.426C492.215,220.74 537.268,275.574 635.98,294.049" style={{fill: 'none', stroke: 'rgb(28,42,78)', strokeWidth: '20px', strokeLinecap: 'round'}}/>
          </g>
          <g id="gust1" transform="matrix(0.594809,0,0,0.983412,-94.6186,-106.656)">
            <path className={'gust'} d="M491,156.209C491,156.209 595.16,298 862,260" style={{fill: 'none', stroke: 'rgb(28,42,78)', strokeWidth: '20px', strokeLinecap: 'round'}}/>
          </g>
          <g id="gust2" transform="matrix(0.594809,0,0,0.983412,-75.5847,-113.592)">
            <path className={'gust'} d="M195.441,485.216C401.094,541.402 405.417,329.115 526,321.234" style={{fill: 'none', stroke: 'rgb(28,42,78)', strokeWidth: '20px', strokeLinecap: 'round'}}/>
          </g>
          <g id="gust3" transform="matrix(0.594809,0,0,0.983412,-59.8574,-136.159)">
            <path className={'gust'} d="M432.559,435.847C478.578,373.556 558.932,354.608 681.252,363.021" style={{fill: 'none', stroke: 'rgb(28,42,78)', strokeWidth: '20px', strokeLinecap: 'round'}}/>
          </g>
        </g>
      </SVG>
    )

}
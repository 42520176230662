import React from 'react';

import {
  Main,
  Header,
  Content,
  Payoff,
  Blurb,
  CTA,
  Segment,
  SegmentHeading,
  SegmentIntro,
  SegmentText,
  ItemHeader,
  P,
  List,
  Footer,
  Devices,
  PhoneScroller,
  PadScroller,
  AboutGrid
  } from './App.css';

import Logo_90E from './components/fx/Logo_90E';
import StreamlineAnim from './components/fx/StreamlineAnim';
import LogoPulse from './components/fx/LogoPulse';
import ConsultancyAnim from './components/fx/ConsultancyAnim';
import DesignAnim from './components/fx/DesignAnim';
import Ribbon from './components/fx/Ribbon';

import Title from './components/title/title';

import Twitter from './svg/twitter.svg';
import LinkedIn from './svg/linkedin.svg';
import Mail from './svg/mail.svg';
import Phone from './svg/phone.svg';
import Hero from './img/jj-ying-7JX0-bfiuxQ-unsplash.jpg';
import Code from './img/code.png';
import Process from './img/process.svg';

import Amsterdam from './img/adam.png';
import TransFollow from './img/transfollow.svg';
import Schipper from './img/schipper.png';
import Lowland from './img/lowland.png';
import GG from './svg/gg.svg';
import A2B from './img/a2b.png';

import Max from './img/max.jpg';
import Torsten from './img/torsten.jpg';

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: 'Progress',
      activeSegmentIndex: 0,
      perspectiveRotate: 0,
      navOpenOnMobile: false,
      mobile: window.innerWidth < 768,
      footerState: ''
    };
    this.setTitle = this.setTitle.bind(this);
    this.scrollToSegment = this.scrollToSegment.bind(this);
    this.toggleNav = this.toggleNav.bind(this);
    this.disableScroll = this.disableScroll.bind(this);
  }

  setTitle(pageTitle) {
    this.setState(prevState => {
      return {
        ...prevState,
        pageTitle,
      };
    });
  }

  toggleNav() {
    const newNavState = !this.state.navOpenOnMobile;
    this.setState(prevState => {
      return {
        ...prevState,
        navOpenOnMobile: newNavState
      };
    });
  }

  scrollToSegment(segment) {
    document.querySelector('#' + segment).scrollIntoView({behavior: 'smooth', block: "start"});
  }

  disableScroll(e){
    console.log('yolo')
    if (this.state.navOpenOnMobile) {
      e.preventDefault();
      return false;
    }
    this.setState(prevState => {
      return {
        ...prevState,
        footerState: ''
      }
    })
  }

  componentDidMount() {
    setTimeout(()=>{

      const segmentOptions = {
        // rootMargin: '0px',
        // threshold: 1
      };

      const segments = [...document.querySelectorAll('h3')];

      const segmentCallback = (entries, observer) => {
        entries.forEach(entry => {
          if(entry.isIntersecting) {
            this.setTitle(entry.target.dataset.prefix);
            let activeSegmentIndex = segments.findIndex((segment) => { return segment.dataset.prefix === entry.target.dataset.prefix });

            this.setState(prevState => {
              return {
                ...prevState,
                activeSegmentIndex,
              };
            });
          }
        });
      };

      const segmentObserver = new IntersectionObserver(segmentCallback, segmentOptions);

      segments.map((segment) => {
        segmentObserver.observe(segment);
        return true;
      });

      //----------------------------------------------------------------------------------------------------------------

      const ornamentOptions = {
        rootMargin: '0px',
        threshold: 0.55
      };
      const ornaments = [...document.querySelectorAll('.ornament')];

      const ornamentCallback = (entries, observer) => {
        entries.forEach(entry => {
          if(entry.isIntersecting)
           entry.target.classList.add('inView');
        });
      };

      const ornamentObserver = new IntersectionObserver(ornamentCallback, ornamentOptions );

      ornaments.map((ornament) => {
        ornamentObserver.observe(ornament);
        return true;
      });


      //----------------------------------------------------------------------------------------------------------------
      window.addEventListener('scroll', ()=>{
        this.setState(prevState => {
          return {
            ...prevState,
            perspectiveRotate: window.scrollY / 2,
            footerState: ''
          }
        })
      });

      //----------------------------------------------------------------------------------------------------------------
      window.addEventListener('resize', (e) => {
        const MobileDetected = window.innerWidth < 768;
        this.setState(prevState => {
          return {
            ...prevState,
            mobile: MobileDetected
          }
        })
      });


      const MobileDetected = window.innerWidth < 768;
      this.setState(prevState => {
        return {
          ...prevState,
          mobile: MobileDetected
        };
      });

    }, 0)
  }

  render() {
    return (
      <Main mobile={this.state.mobile} id={"Main"}>
        <Header activeIndex={this.state.activeSegmentIndex} dropdown={this.state.mobile} opened={this.state.navOpenOnMobile} onClick={this.toggleNav}>
          <div onClick={()=>{this.scrollToSegment('Hero')}}>
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <Logo_90E />
            <Title prefix={this.state.pageTitle}/>
          </div>
          <nav>
            {/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
            {/* eslint-disable jsx-a11y/anchor-is-valid */}
            <a onClick={()=>{this.scrollToSegment('Hero')}}>Home</a>
            <a onClick={()=>{this.scrollToSegment('Integratie')}}>Integratie</a>
            <a onClick={()=>{this.scrollToSegment('Development')}}>Development</a>
            {/*<a onClick={()=>{this.scrollToSegment('Cases')}}>Cases</a>*/}
            <a onClick={()=>{this.scrollToSegment('Advies')}}>Advies</a>
            <a onClick={()=>{this.scrollToSegment('Over ')}}>Over ons</a>
          </nav>
        </Header>

        <Content className={'App'}>
          <img alt={"Hero background of ocean shore"} src={Hero} style={{transform: 'translate3d(0,0,0)', position: 'absolute', width: '100vw', height: '100vh', objectPosition: 'bottom center', objectFit:'cover', opacity: 0.75-(this.state.perspectiveRotate / 250)}}/>

{/*------------------------------------------------------------------------------------------------------------------*/}

          <Segment id={"Hero"}>
            <Ribbon ribbon={0} className={'segment0'} style={{position: 'relative', top: '55vmin', display: 'block'}}/>
            <Ribbon ribbon={1} style={{position: 'relative', top: '55vmin', display: 'block'}}/>
            <SegmentHeading data-prefix={'Progress'} />
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <Payoff >Van gedateerd naar ge&iuml;ntegreerd.<br/><span>Wat werkt, hoeft niet weg. Maar het kan <em>altijd</em> beter.</span></Payoff>
                <Blurb>
                  Je bedrijf groeit. Dat is mooi, maar wat als het handwerk binnen je IT-omgeving uit de hand loopt?
                  <br/>
                  <br/>
                  90East zorgt ervoor dat bedrijfsinformatie zo efficiënt mogelijk verwerkt wordt, <br/>
                  zodat je medewerkers weer volop kunnen doen waar ze goed in zijn.
                </Blurb>
                <CTA onClick={()=>{this.scrollToSegment('Integratie')}}>hoe wij helpen</CTA>
              </div>
          </Segment>

{/*------------------------------------------------------------------------------------------------------------------*/}

          <Segment id={"Integratie"}>
            <StreamlineAnim />
            <SegmentHeading  data-prefix={'Productivity'}>Stroomlijn de zaak</SegmentHeading>
            <SegmentIntro>
              Je vermoedt dat je bedrijfsvoering efficiënter kan maar je weet niet hoe? Aangenaam, 90East.
            </SegmentIntro>
            <SegmentText>
              <div >
                <P>
                  Ieder bedrijf is uniek, ook hun informatiestromen. Hoe goed ook, de kans is groot dat deze processen ondertussen aan een onderhoudsbeurt toe zijn. Bedrijfsvoering groeit en verandert gaandeweg, maar de tools &amp; technieken blijven vaak achter.
                  <br /><br />
                  90East analyseert de informatiestromen en onderzoekt waar moderne IT-oplossingen zoals webapps &amp; API's uitkomst kunnen bieden in combinatie met de huidige processen.
                  We kijken hoe we met minimale aanpassingen deze nieuwe technieken kunnen integreren aan de reeds bestaande systemen zodat de drempel om te vernieuwen zo laag mogelijk blijft.
                </P>
                <P>
                  Zo ziet 90East altijd winst in het inzetten van mobiele webapps. Deze moderne techniek stelt de gebruiker in staat om waar dan ook zijn of
                  haar werk te doen dankzij altijd parate toegang tot de belangrijkste systemen van je bedrijf.
                </P>
                <div>
                  <ItemHeader>90East biedt:</ItemHeader>
                  <List>
                    <li>Onafhankelijk advies</li>
                    <li>Ontsluiting en inlezing van informatie via API's</li>
                    <li>Mobiele webapps voor je bestaande systemen</li>
                    <li>Koppeling van producten via API's</li>
                    <li>User interface design</li>
                  </List>
                </div>
              </div>
              <Devices>
                <PadScroller />
                <PhoneScroller />
              </Devices>
            </SegmentText>
          </Segment>

{/*------------------------------------------------------------------------------------------------------------------*/}

          <Ribbon ribbon={0} className={'segment0'} style={{position: 'relative', bottom: '-15vmin', transform: 'scaleX(-1)'}}/>
          <Ribbon ribbon={1} className={'segment0'} style={{transition: 'all 0.2s linear', position: 'relative', bottom: '-300vmin', transform: 'rotateY(180deg) scaleY(-1)', transformOrigin: 'top', display: 'block'}}/>

{/*------------------------------------------------------------------------------------------------------------------*/}

          <Segment id={"Development"}>
            <LogoPulse/>
            <SegmentHeading data-prefix={'Technology'}>API & Software Development</SegmentHeading>
            <SegmentIntro>
              Grote ambities, maar geen relevante kennis in huis? Dan helpen wij toch gewoon.
            </SegmentIntro>
              <SegmentText>
                <img className={'ornament'} src={Code} alt={'Code snippet'} style={{width: '70%', maxWidth: '45vw', maxHeight: '75vh', objectFit: 'contain', objectPosition: 'center top', position: 'relative', top: '-45px', margin: '0 auto'}}/>
                <div>
                  <P>
                    Vaak liggen de plannen voor de toekomst van je bedrijf al lang en breed klaar, maar ontbreekt het gewoon aan mankracht of kennis om alle wensen waarheid te maken.
                    Ook in dit geval kan 90East helpen.
                    Met gedegen kennis van zowel server-side als client-side technologieën kunnen wij inspringen waar nodig onder het motto <em>u vraagt, wij draaien er niet omheen.</em><br/>
                    Daarbij komt nog eens dat 90East als geen ander weet dat een tool pas echt goed werkt als het er ook goed uitziet. Daarom werken wij altijd vanuit het oogpunt van de gebruiker die een moderne, gebruiksvriendelijke user interface verwacht.
                  </P>
                  <div style={{display: 'flex', flexDirection: this.state.mobile ? 'column' : 'row'}}>
                    <div>
                      <ItemHeader>Webapps</ItemHeader>
                      <P>
                        Backend-systemen toegankelijk maken voor medewerkers, partners of gebruikers: dat is waar 90East waarde ziet. Met webapps kan 90East deze data beschikbaar stellen op elke moderne telefoon, tablet of computer. Met onze expertise in user interfaces maken wij het geheel uiteraard volgens uw huisstijl.
                      </P>
                    </div>
                    <div>
                      <ItemHeader>API Development</ItemHeader>
                      <P>
                        Met jaren ervaring in API ontwikkeling en systeemintegratie is 90East de aangewezen partner om verschillende losstaande systemen op een betrouwbare manier te koppelen, zodat hun
                        gebundelde kracht optimaal is te benutten. Daarnaast zijn REST API's ook een geschikt middel om webapps van data te voorzien.
                      </P>
                    </div>
                  </div>
                  {/*<div>*/}
                  {/*  <ItemHeader>Wij werken o.a. met:</ItemHeader>*/}
                  {/*  <List>*/}
                  {/*    <li>Java (Spring)</li>*/}
                  {/*    <li>Node.js</li>*/}
                  {/*    <li>React JS</li>*/}
                  {/*    <li>REST API's</li>*/}
                  {/*    <li>MySQL / PostgreSQL / MongoDB</li>*/}
                  {/*    <li>HTML/CSS</li>*/}
                  {/*    <li>Continuous Development & Integration</li>*/}
                  {/*    <li>Automatic Testing</li>*/}
                  {/*  </List>*/}
                  {/*</div>*/}
                </div>
              </SegmentText>
          </Segment>

{/*------------------------------------------------------------------------------------------------------------------*/}

          {/* <Segment id={"Cases"} style={{paddingLeft: 0, paddingRight: 0}}> */}
          {/*   <DesignAnim  /> */}
          {/*   <SegmentHeading data-prefix={'Identity'}>Cases</SegmentHeading> */}
          {/*  <SegmentIntro>*/}
          {/*    Een sterke, unieke identiteit maakt het verschil tussen vergeten en onvergetelijk.*/}
          {/*  </SegmentIntro>*/}
          {/*  <Gallery />*/}
          {/*</Segment>*/}


{/*------------------------------------------------------------------------------------------------------------------*/}

          <Segment id={"Advies"}>
            <ConsultancyAnim />
            <SegmentHeading data-prefix={'Experience'}>Consultancy & advies</SegmentHeading>
            <SegmentIntro>Jij weet alles van jullie markt. Wij weten alles over IT. Dat is een win-win.</SegmentIntro>
            <SegmentText>
              <div>
              <P>
                Vaak brengt een vers paar ogen ideëen of inzichten die anders nooit boven water zouden komen. 90East kan helpen aanwijzen hoe organisaties gestroomlijnd kunnen worden, of dat nu op technisch of organisatievlak is.
                <br /><br />
                90East maakt een gedegen rapportage van je bedrijf op basis van 3 kernvragen: <em>Wie zijn we?</em>, <em>Wat doen we?</em> en <em>Waarom doen we het zoals we doen?</em>.
                Drie simpele vragen met wellicht ook simpele antwoorden, maar simpele antwoorden zijn niet altijd simpel gevonden. Echter, de antwoorden op die drie vragen samen geven een duidelijk beeld van de processen die in gebruik zijn en —belangrijker— hoe zij verbeterd kunnen worden.
              </P>
              <P>
                Uiteraard is 90East een IT-georiënteerd bedrijf, dus zullen wij al snel een technische aanpak adviseren. Niet omdat we daar zelf goed in zijn, maar omdat wij geloven dat IT een sleutelrol kan spelen in het optimaliseren van bedrijfsprocessen zonder dat daar peperdure integratieprojecten voor opgezet hoeven te worden. 90East weet als geen ander waar snelle winst valt te behalen door slimme inzet van webapps, integraties en proceswijzigingen. Sterker nog: wij evalueren continu onze eigen processen om de tools die we gebruiken optimaal in te zetten.
              </P>
              </div>
              <img className={'ornament'} style={{maxHeight: '40vh', margin: '0 auto'}} src={Process} alt={"Verzamelen, Verkennenn, Vastleggen, Realiseren"}/>
            </SegmentText>
          </Segment>

{/*------------------------------------------------------------------------------------------------------------------*/}

          <Segment id={"Over"}>
            <DesignAnim  />
            <SegmentHeading data-prefix={'Character'}>Over ons</SegmentHeading>
            <SegmentIntro>Wat we doen zegt meer dan wie we zijn.</SegmentIntro>
            <AboutGrid>
              <div>
                <P>
                  Wat we in ieder geval niet zijn is snelle jongens in maatpakken met dure auto's. We geloven niet dat je een grote multinational hoeft te zijn om een optimale bedrijfsvoering te realiseren: persoonlijk contact met een eerlijke basis is veel belangrijker. Wat we wel zijn? Wij zijn 90East: gewone jongens met verstand van IT & webapps.
                  <br /><br />
                  90East is een bedrijf met een mening, en dat is ook precies de reden waarom we doen wat we doen: wij zijn van mening dat iedereen geholpen kan worden met een stevige, eerlijke blik naar binnen. Het is goed om de ogen op de toekomst gericht te houden, maar soms brengt even pas op de plaats je even ver, zo niet verder.
                </P>
                <ItemHeader>Wanneer beginnen we?</ItemHeader>
                <P>
                  We zoeken altijd naar wat de beste oplossing is, wat dat ook is. We wringen ons niet in bochten om altijd maar hetzelfde systeem in te zetten, en hebben ook geen contracten met toeleveranciers van producten of diensten: dat zou de makkelijke weg zijn, maar niet de meest optimale voor de unieke projecten die we doen voor onze klanten. We hebben eer van ons werk, tot in en juist door de kleinste details. Dus, wanneer beginnen we?
                </P>
              </div>
              <div style={{display: 'flex', flexDirection: this.state.mobile ? 'column' : 'row', textAlign: 'center', alignItems: 'center', justifyContent: 'space-around'}}>
                <figure>
                  <img className={'ornament'} src={Torsten} alt={"Torsten Onderwater"}/>
                  <figcaption>
                    <strong>Torsten</strong><br />
                    Back-end, API's, Blauwe IJsbeer, Sport
                  </figcaption>
                </figure>
                <figure>
                  <img className={'ornament'} src={Max} alt={"Max Steenbergen"}/>
                  <figcaption>
                    <strong>Max</strong><br/>
                    Front-end, design, branding &amp; Weizen
                  </figcaption>
                </figure>
              </div>
              <div>
                <a href={'https://www.a2b-online.com/a2b-nl/'} target="_blank" rel="noopener noreferrer"><img src={A2B} alt={"A2B Online"} /></a>
                <a href={'https://www.transfollow.org'} target="_blank" rel="noopener noreferrer"><img src={TransFollow} alt={"TransFollow"} /></a>
                <a href={'https://schipperbootcamp.nl/'} target="_blank" rel="noopener noreferrer"><img src={Schipper} alt={"Logo Schipper Bootcamp"} /></a>
                <a href={'https://grid-games.com/'} target="_blank" rel="noopener noreferrer"><img src={GG} alt={"Logo Grid Games"} /></a>
                <a href={'https://www.lowland-aerospace.com/'} target="_blank" rel="noopener noreferrer"><img src={Lowland} alt={"Logo Lowland Aerospace"} /></a>
                <a href={'https://www.amsterdam.nl/'} target="_blank" rel="noopener noreferrer"><img src={Amsterdam} alt={"Logo Gemeente Amsterdam"} /></a>
              </div>
            </AboutGrid>
            <SegmentIntro style={{width: '100vw'}}>Deze organisaties profiteren al van ons werk</SegmentIntro>
            <CTA style={{marginTop: '48px', marginBottom: '48px'}} onClick={()=>{
              this.setState(prevState => {
                return {
                  ...prevState,
                  footerState: 'opened'
                }});
                window.location = 'mailto:info@90east.nl'}}>Dat wil ik ook!</CTA>
          </Segment>

{/*------------------------------------------------------------------------------------------------------------------*/}

          <Footer className={this.state.footerState}>
            <address>
              <h6>90East</h6>
              De Kwekerij 14<br />
              2394 DC, Koudekerk ad Rijn<br />
              <a href={'mailto:info@90east.nl'}>info@90east.nl</a><br />
              <a href={'tel:0644694474'}>06 44 69 44 74</a>
            </address>
            <div>
              <a target='_blank' rel='noopener noreferrer' href={'https://twitter.com/_90east'}><img src={Twitter} alt={'90East op Twitter'}/></a>
              <a target='_blank' rel='noopener noreferrer' href={'https://www.linkedin.com/company/51640311'}><img src={LinkedIn} alt={'90East op LinkedIn'}/></a>
              <a target='_blank' rel='noopener noreferrer' href={'mailto:info@90east.nl'}><img src={Mail} alt={'Mail 90East'}/></a>
              <a href={'tel:0612345678'}><img src={Phone} alt={'Bel 90East'}/></a>
            </div>
          </Footer>
        </Content>
      </Main>
    );
  }
}
